import { Box, Typography } from "@mui/material";
import React from "react";

const Span = (props) => {
  const { span } = props;
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: { lg: "16px", md: "14px", sm: "12px", xs: "18px" },
            lineHeight: "normal",
            textAlign: "left",
            fontFamily: "Montserrat",
            color: "rgb(66,66,66)",
            fontWeight: "normal",
            letterSpacing: "0.1em",
          }}
          variant="p"
        >
          {span}
        </Typography>
      </Box>
    </>
  );
};

export default Span;
