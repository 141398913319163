import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom/dist";
const CustomBtn = (props) => {
  const { title, to } = props;

  return (
    <>
      <NavLink to={to}>
        <Button
          variant="contained"
          sx={{
            color: "#fff !Important",
            fontSize: "15px",
            paddingX: "35px",
            paddingY: "10px",
            fontWeight: "bold",
            backgroundColor: "#322D31",
            textTransform: "capitalize",
            letterSpacing: "0.15em",
            "&:hover": {
              backgroundColor: "#fff ",
              color: "#322D31",
            },
          }}
        >
          {title}
        </Button>
      </NavLink>
    </>
  );
};

export default CustomBtn;
