import React from "react";
import Breaker from "../Breaker/Breaker";
import { Box } from "@mui/material";
import { Span, SubTitle, Title } from "../../mini";

const Leadership = () => {
  return (
    <>
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box>
          <Breaker img="https://static.wixstatic.com/media/e9f997_c7fdaac036af4e82a938d13bcb9b5c35~mv2.jpg/v1/fill/w_980,h_603,al_b,q_85,usm_0.66_1.00_0.01,enc_auto/e9f997_c7fdaac036af4e82a938d13bcb9b5c35~mv2.jpg" />
        </Box>
        <Box
          sx={{
            backgroundImage:
              "url(https://static.wixstatic.com/media/e9f997_2dc2a657613d447aa1d3b15aecbd19d9~mv2.jpg/v1/fill/w_1920,h_959,al_b,q_85,usm_0.66_1.00_0.01,enc_auto/e9f997_2dc2a657613d447aa1d3b15aecbd19d9~mv2.jpg)",
            width: "100%",
            height: "110vh",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <img
              alt=""
              src="https://static.wixstatic.com/media/e9f997_346cb6ea21714197b6f3656018c773bc~mv2.png/v1/crop/x_0,y_0,w_555,h_594/fill/w_84,h_90,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Heard%20Brand_Centered%20Combo%20Mark_Black_CM.png"
              style={{ marginTop: "5%" }}
            />
            <Box sx={{ marginX: "20%" }}>
              <Box sx={{ marginY: "20px" }}>
                <SubTitle SubTitle="LEADERSHIP" />
              </Box>
              <Box sx={{ marginTop: "2%" }}>
                <Title title="Heard has heart." />
              </Box>
              <Box sx={{ marginY: "10%", paddingX: "15%" }}>
                <Span span="Since incorporating 25 years ago as an event production and management company, our engagements have delivered premier results that our partners and customers have come to expect. Unlike any of our competitors, we aggressively expanded and recruited talent that other companies sacrificed during the pandemic. We are committed. We engage people, create moments and build connections. " />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Leadership;
