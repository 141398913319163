import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Span, SubTitle, Title } from "../../mini";
import roadase from "../../../assets/road-case copy.webp";
import logo from "../../../assets/Heard Brand_Centered Combo Mark_Black_CM.webp";
import zIndex from "@mui/material/styles/zIndex";
import Breaker from "../Breaker/Breaker";
const Client = () => {
  const InData = [
    "Financial",
    "Automotive",
    "Technology",
    "Healthcare",
    "Retail",
  ];
  return (
    <>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            display: { xs: "none", lg: "flex", md: "flex" },
            height: "100%",
          }}
        >
          <video
            role="presentation"
            crossOrigin="anonymous"
            playsInline
            preload="auto"
            muted
            loop
            autoPlay
            tabIndex={-1}
            src="https://video.wixstatic.com/video/e9f997_73a1751a49c54d22b86d415253307f62/1080p/mp4/file.mp4"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>

        <Grid
          container
          lg={12}
          sx={{
            zIndex: 1,
            marginTop: "-50%",
            marginLeft: "5%",
            position: "relative",
          }}
        >
          <Grid item lg={6}>
            <Box
              sx={{
                paddingBottom: "5%",
                marginLeft: "-50px",
              }}
            >
              <Title title="Expanding our horizons, one client at a time." />
            </Box>
            <Box
              sx={{
                paddingLeft: { lg: "13%", md: "22%", xs: "18%" },
                paddingRight: { lg: "0%", md: "25%", xs: "18%" },
                paddingY: "2%",
              }}
            >
              <Span span="Our client industry list is backed by a portfolio to prove it. No matter the industry, our approach remains the same." />
            </Box>
            <Box
              sx={{
                paddingLeft: { lg: "13%", md: "22%", xs: "18%" },
                paddingRight: { lg: "0%", md: "25%", xs: "18%" },
                paddingY: "2%",
              }}
            >
              <Span span="We are here to produce exceptional event experiences for our clients, before, during and post event. Our goal is to become a trusted partner and extension of our clients. This helps us enhance the overall event production and planning experience, adding value at every stage of the process." />
            </Box>
            <Box
              sx={{
                paddingLeft: { lg: "13%", md: "22%", xs: "18%" },
                paddingRight: { lg: "0%", md: "25%", xs: "18%" },
                paddingY: "2%",
              }}
            >
              <Span
                span=" Due to the high profile nature of the companies we work with, we put
            great effort into the protection of their information. This is a top
            priority for us. Our equipment, computers and team members we work
            with on site are each held to a high security standard. An integral
            part of working with Heard is knowing we ensure the protection of
            our clients' information and data."
              />
            </Box>
            <Box
              sx={{
                paddingLeft: { lg: "13%", md: "22%", xs: "18%" },
                paddingRight: { lg: "0%", md: "25%", xs: "18%" },
                paddingY: "2%",
              }}
            >
              <Span span=" Heard is not just about getting the job done right, we're here to show how it's done better." />
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box
              sx={{
                marginTop: "-30%",
                zIndex: 4,
                position: "relative",
              }}
            >
              <img alt="" src={roadase} width="60%" />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "center", marginX: "10%" }}>
          <Grid container lg={12} sx={{}}>
            {InData.map((item, index) => (
              <Grid item lg={3} key={index}>
                <Box sx={{ margin: "5%" }}>
                  <Paper
                    elevation={7}
                    sx={{
                      padding: "20px",
                      "&:HOVER": {},
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }} variant="h4">
                      {item}
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Client;
